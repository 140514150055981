import React from "react";
import Signup from "../features/auth/components/Signup";

function SignUpPage() {
  return <div>
    <Signup></Signup>
  </div>;
}

export default SignUpPage;
