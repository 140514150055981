import React from "react";
import Login from "../features/auth/components/Login";

function LoginPage() {
  return (
    <div>
      <Login></Login>
    </div>
  );
}

export default LoginPage;
